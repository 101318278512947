const { on } = require("modernizr");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "posts") {
        let categories_selected = [];
        let post_id = $("#post_id").val()
        let element_id;
        let sliders_removed_ids = []
        let removed_cities = []
        let editorContent;
        let check_post = "";
        let process = []

        if ($('#user_type').val() == 'administrator') {
            check_post = {
                'targets': 0,
                'checkboxes': {
                     'selectRow': false,
                     'selectCallback': function(nodes, selected) {
                         refresh_selected_elements()
                     }
                 }
             }
        }
        $('[data-submenu]').submenupicker();
        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }

            $.fn.dataTable.ext.search.pop();
        })

        table = $('#table-posts').DataTable({
            'initComplete': function(settings){
                var api = this.api();

                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            "serverSide": true,
            "ajax": {
                "url": $('#table-posts').data('source'),
                "data": function(e){
                    e.post_status = $("input[name='post_status']:checked").val()
                    e.post_process = process
                    e.visibility = $("input[name='post_visibility']:checked").val()
                    e.city_id = $("#post-filter").val()
                    e.categories = $("#post-filter-categories").val()
                    e.category = $("#category_parent").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                
                {"data": "id",
                    "visible": check_post != "" ? true : false,
                    "render": function(data, display, record) {
                    return check_post != "" ? "<input type='checkbox' class='dt-checkboxes' autocomplete='off'>" : ''
                }},
                {"data": "id"},
                {"data": "title",
                    "render": function(data, display, record) {
                        return `<a href="/mccadmin/cities/posts/${record.id}/edit">${data}</a>`
                    }
                },
                {"data": "process"},
                {"data": "total_booking"},
                {"data": "city"},
                {"data": "status"},
                {"data": "visibility"},
                {"data": "created_at",
                    "render": function(data) {
                        return `<div title='${data}'>${moment.utc(data).format("DD-MM-YYYY HH:mm")}</div>`
                    }
                },
                {"data": "tags"},
                {"data": "actions",
                 "render": function(data, display, record) {
                    return `<a href="/mccadmin/cities/posts/${record.id}/edit" class="edit-element" title="Edit"><i class="fa-solid fa-pen"></i></a>
                            ${check_post != "" ? "<a href='javascript:' class='delete-element' title='Delete'><i class='fa-solid fa-trash-can'></i></a>"  : ''}`
                 }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                $(row).attr("data-status", data.status_raw)
                $(row).attr("data-visibility", data.visibility_raw)
                //$('input', row).prop('disabled', true);
            },
            'columnDefs': [
                check_post,
                { target: 9,
                    visible: false,
                    searchable: true
                },
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "pageLength": 50,
            "ordering": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            order : [[8,'desc']],
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
        });
        $("#table-posts_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        // $("#table-posts_wrapper > .row > div:first").append("<span class='filter-planning_passed'>" +
        //     "<div class='form-group' style='width:200px; display: table-cell; text-align: left;'>" + 
        //         "<select id='post-filter' class='full-width' name='post[cities]' data-init-plugin='select2' required>" +
                    
        //         "</select>" + 
        //     "</div>" + 
        // "</span>")

        $("#table-posts_wrapper > .row > div:first").append(`<div class="col-9">
           
        </div>`)

        $("#table-posts_wrapper > .row > div:first").append("<div class='col-8 table-status'><span>" +
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='post_status' id='post_status_all' value='all' checked>" +
                "<label for='post_status_all'>" + 
                    "All " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline pr-0'>" + 
                "<input type='radio' name='post_status' id='post_status_draft' value='draft'>" +
                "<label for='post_status_draft'>" + 
                    "Draft " +
                "</label>" +
            "</div>" + 
            "<div class='form-check form-check-inline '>" + 
                "<input type='radio' name='post_status' id='post_status_published' value='published'>" +
                "<label for='post_status_published'>" + 
                    "Published " +
                "</label>" +
            "</div>" +
            "<div class='form-check form-check-inline complete'>" + 
                "<input type='checkbox' name='post_process' id='post_process_approved' value='approved'>" +
                "<label for='post_process_approved'>" + 
                    "Approved " +
                "</label>" +
            "</div>" +
            "<div class='form-check form-check-inline complete'>" + 
                "<input type='checkbox' name='post_process' id='post_process_checked' value='checked'>" +
                "<label for='post_process_checked'>" + 
                    "Checked " +
                "</label>" +
            "</div>" +
            "<div class='form-check form-check-inline complete'>" + 
                "<input type='checkbox' name='post_process' id='post_process_not_checked' value='not_checked'>" +
                "<label for='post_process_not_checked'>" + 
                    "Not checked " +
                "</label>" +
            "</div>" +
            // "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
            //     "<input type='checkbox' name='post_visibility' id='post_status_visibility' value='private' checked>" +
            //     "<label for='post_status_visibility'>" + 
            //         "Private" +
            //     "</label>" +
            // "</div>" +
        "</span></div>")

        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            }).on('select2:open', function () {
              $.fn.scrollbar && $('.select2-results__options').scrollbar({
                
              })
            });
        });

        $("#post-city").select2({
            placeholder: `Select ${$('#post-city').prev().text()}`,
            minimumResultsForSearch: -1
        });

        $("#post-city").on('select2:select', function(e) {
            for( var i = 0; i < removed_cities.length; i++){ 
                if ( removed_cities[i] == e.params.data.id) { 
                    removed_cities.splice(i, 1); 
                }
            }
        })

        $("#post-city").on('select2:unselecting', function(e) {
            removed_cities.push(parseInt(e.params.args.data.id))
        })

        $("#post-filter").select2({
            minimumResultsForSearch: -1,
            placeholder: "Filter Cities"
        });
        
        $("#post-filter-categories").select2({
            minimumResultsForSearch: -1,
            placeholder: "Filter Categories"
        });

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
        
        const pond_sliders = FilePond.create(document.querySelector('#post-sliders'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 8000,

            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'post[sliders]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 800000,
        });

        const pond_image = FilePond.create(document.querySelector('#post-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 8000,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'post[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 8000000,
        });

        const pond_seo_image = FilePond.create(document.querySelector('#post-seo-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 400,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'post[seo_attributes][image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 8000000,
        });

        $(".btn-slider-delete").on("click", function(e){
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    sliders_removed_ids.push($(e.currentTarget).data('slider_id'))
                    $("input[name='post[slider_remove]']").val(sliders_removed_ids.join(','))
                    $(this).parent().remove()
                }
            })
        })

        // toggle status
        $("input[name='post_status'], input[name='post_visibility']").on("change", function() {
            table.ajax.reload()
        })

        // toggle process
        $("input[name='post_process'], input[name='post_process']").on("change", function() {
            let val = $(this).val()
            
            if (!process.includes(val)) {
                process.push(val)
            }
            else {
                process.splice(process.indexOf(val), 1)
            }

            console.log(process)
            table.ajax.reload()
        })

        // toggle city
        $("#post-filter").on("select2:select", function(e) {
            filtering()
        })
        
        $("#post-filter").on("select2:unselect", function(e) {
            filtering()
        })

        $("#post-filter").on("select2:closing", function(e) {
            if ($("#post-filter").val().length == 0) {
                let post_status =  $("input[name='post_status']:checked").val()

                $.fn.dataTable.ext.search.pop();
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                    return ($(table.row(dataIndex).node()).attr('data-status') == post_status || post_status == 'all');

                })
                table.draw();
            }
        })

        $("#post-filter-categories").on("select2:select", function(e) {
            table.ajax.reload()
        })

        $("#post-filter-categories").on("select2:unselect", function(e) {
            table.ajax.reload()
        })

        $('.custom-tag-input').tagsinput();
        
        // *** EVENTS

        $("#table-posts tbody").on("click", ".delete-element", function() {
            element_id = $(this).parent().parent().attr("data-element_id")
            console.log(element_id)
            destroy(element_id, null)
        })

        $("#delete-selected-elements").on("click", function() {
            elements = rows_selected

            destroy(null, rows_selected.join(","))
        })

        $('#btn-save').on("click", function(e) {
            $("#form-post").validate()
            $('#form-post').on("submit", function(e) {
                e.preventDefault() 
            })

            if ($("#form-post").valid()) {
                $("input[name='post[removed_cities]']").val(removed_cities)
                document.getElementById("form-post").submit()
            }
        })

        $(".image-city").on("click", function() {
            $(".image-city").removeClass("selected")
       
            $("input[name='post[postable_id]']").val($(this).data("city_id"))

            $(this).addClass("selected")
        })

        // ClassicEditor.create( document.querySelector( '#post-content' ), {
        //     ckfinder: {
        //         uploadUrl: '/public/tmp'
        //     }
        // } ).then( editor => {
        //     editorContent = editor
        //     } )
        // .catch( error => {
        //     console.error( error );
        // } );

        // ClassicEditor.create(document.querySelector('#post-content'), {
        //     simpleUpload: { uploadUrl: '/uploads' },
        // })

        $("#btn-preview").on("click", function() {
            let formData = new FormData();
            formData.append('origin', $("#post_id").val());
            formData.append('title', $("#post_title").val());
            formData.append('content', editorContent.getData());
            formData.append('address', $("#post_address").val());
            formData.append('contact', $("#post_contact").val());
            formData.append('instagram', $("#post_instagram").val());
            formData.append('twitter', $("#post_twitter").val());
            formData.append('facebook', $("#post_facebook").val());
            formData.append('website', $("#post_website").val());
            formData.append('long', $("#map-long").val());
            formData.append('lat', $("#map-lat").val());
            if (FilePond.find(document.querySelector('#post-image')).getFile() != null)
                formData.append('image', FilePond.find(document.querySelector('#post-image')).getFile().file)

            fetch(`/mccadmin/cities/posts/post-preview`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        //window.location = "/travel-features/preview/paris/" + data.slug
                     
                        window.open("/travel-features/preview/paris/" + data.id, '_blank');
                    })
                }
            })
        })

        $(`#export-selected-elements`).on("click", function(e) {
            $("#modal-post_export_pdf").modal("show")
        })

        $("#btn-create_pdf").on("click", function(e) {
            let formData = new FormData();
            formData.append('header_post_pdf', $('#post_pdf_header').val());

            fetch(`/mccadmin/cities/posts/export`, {
                method: 'PATCH',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                       
                        window.open('/mccadmin/cities/posts/export/?ids=' + rows_selected.join(','), '_blank')
                    })
                }
            })
        })

        $('.btn-process').on('click', function(e) {
            $("#process-state").val($(e.currentTarget).data('process'))
            $('.booking-state_label').text($(e.currentTarget).children().eq(1).text())
            $('.booking-state_label').siblings('span').attr('class', '').toggleClass($(e.currentTarget).children().eq(0).attr('class'))
            
            $("#post_process").val($(e.currentTarget).data('process'))
        })

        // *** FUNCTIONS
        function getTypePost() {
            if ($("#post-filter").length > 0) {
                let heading = $("input[name='post[heading]']").data('heading')
               
                fetch(`/mccadmin/${heading}/${heading}/get-cities`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                           
                            $("#post-filter").append(`<option value=''>All ${heading}</option>`)
                            jQuery.each(data, function (i, val) {
                                $("#post-filter").append(`<option value='${val.id}'>${val.name}</option>`)
                            })

                            $("#post-filter option:first").trigger("update")
                        })
                    }
                })
            }
        }

        function getCategories() {
            let heading = $("input[name='post[heading]']").data("heading");
            let url = `/mccadmin/${heading}/categories/getAll`;

            if (post_id != undefined)
                url += `/${post_id}`
 
            fetch(url, { 
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {
                        var treeData = []

                        $.each(data.categories1, function(index, rowId){
                            treeData.push(data.categories1[index][0])
                        });

                        jQuery.each(data.all, function (i, val) {
                            $("#post-filter-categories").append(`<option value='${val.id}'>${val.title}</option>`)
                        })
                        $("#post-filter-categories option:first").trigger("update")

                        $("#categories-tree").dynatree({
                            checkbox: true,
                            selectMode: 2,
                            children: treeData,
                            onSelect: function(select, node) {
                                categories_selected = []
                                var selNodes = node.tree.getSelectedNodes();

                                var selKeys = $.map(selNodes, function(node) {
                                    categories_selected.push(node.data.key)
                                    //return "[" + node.data.key + "]: '" + node.data.title + "'";
                                });
                                $("input[name='post[categories]']").val(categories_selected.join(","));
                         
                            },
                            // onClick: function(node, event) {
                            //     // We should not toggle, if target was "checkbox", because this
                            //     // would result in double-toggle (i.e. no toggle)
                            //     if (node.getEventTargetType(event) == "title")
                            //         node.toggleSelect();
                            // },
                            // onKeydown: function(node, event) {
                            //     if (event.which == 32) {
                            //         node.toggleSelect();
                            //         return false;
                            //     }
                            // },
                            // // The following options are only required, if we have more than one tree on one page:
                            // cookieId: "dynatree-Cb2",
                            // idPrefix: "dynatree-Cb2-"
                        });
                        
                        if ($("#post-selected_categories").val() != undefined) {
                            for(let category of $("#post-selected_categories").val().split(',')) {
                                $("#categories-tree").dynatree("getTree").selectKey(category)
                            }
                        }
                       
                    })
               
                }
            })

        }

        function destroy(element_id, elements_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.disable()

                    if (element_id != null) {
                        return fetch(`/mccadmin/cities/posts/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {

                                if ($(".delete-element").data("redirect") == true) {
                                    window.location = "/mccadmin/cities/posts/"
                                }
                                else
                                    table.row($(`tr[data-element_id='${element_id}']`)).remove().draw();

                                toastr.info('Post successfully deleted!')
                            }
                            else {
                                response.json().then(function(data) {
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(7) .element-deleting`).addClass("hidden");
                                    $(`.table tr[data-element_id='${element_id}'] td:nth-child(7) .element-actions`).removeClass("hidden");
                                    table.cells(`tr[data-element_id='${element_id}']`, 0).checkboxes.enable()
                                    table.columns().checkboxes.deselectAll()
                                    
                                    Swal.fire({
                                        icon: 'error',
                                        text: data.error,
                                    })
                                })
                            }
                        })
                    }
                    else {
                        $(".total-selected-content").addClass("hidden")

                        let formData = new FormData();
                        formData.append('elements_id', elements_id);

                        return fetch(`/mccadmin/cities/posts/delete_elements`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            
                            if (response.ok) {
                                response.json().then(function(data) {
         
                                    if (data.elements == 0) {
                                        Swal.fire({
                                            icon: 'error',
                                            text: data.error,
                                        })
                                    }
                                    else {
                                        elements = data.elements
                                        table.columns().checkboxes.deselectAll()

                                        $.each(elements, function(index, rowId){
                                            table.row($(`tr[data-element_id='${rowId}']`)).remove().draw();
                                        });

                                        $(".total-selected-content").addClass("hidden")

                                        toastr.info('Posts successfully deleted!')
                                    }
                                })
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }
        
        function filtering() {
            let post_status =  $("input[name='post_status']:checked").val()
            let post_visibility =  $("input[name='post_visibility']:checked").val()
            if (post_visibility == undefined) {
                post_visibility = 'public'
            }
            let city_id = $("#post-filter").val()

            $.fn.dataTable.ext.search.pop();
            $.fn.dataTable.ext.search.push(
                function(settings, data, dataIndex) {
                    let cities = $(table.row(dataIndex).node()).attr('data-city_ids').split(',')
                    //return ($(table.row(dataIndex).node()).attr('data-city_id') == city_id || city_id == '') && ($(table.row(dataIndex).node()).attr('data-status') == post_status || post_status == 'all');

                    for (city of cities) {
                        if ((city_id.includes(city) || city_id == '') && ($(table.row(dataIndex).node()).attr('data-status') == post_status || post_status == 'all')  && ($(table.row(dataIndex).node()).attr('data-visibility') == post_visibility))
                            return true
                        else
                            false
                    }
                }
            );
        
            table.draw();
        }

        function refresh_selected_elements() {
            rows_selected = table.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
            
            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)
          
            return rows_selected
        }

        getCategories()
        getTypePost()
   
        function tableToCSV(category_id, cities, categories) {

            fetch(`/mccadmin/cities/posts/all-posts?category_id=${category_id}&cities=${cities}&categories=${categories}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let csv_data = [];
                      
                        var csvrow = [];
                        csvrow.push('title');
                        csvrow.push('content');
                        csvrow.push('city');
                        csvrow.push('address');
                        csvrow.push('contact');
                        csvrow.push('instagram');
                        csvrow.push('facebook');
                        csvrow.push('twitter');
                        csvrow.push('website');
                        csvrow.push('location');
                        csvrow.push('tags');
                        csvrow.push('categories');
                        csv_data.push(csvrow.join(","));

                        /*var csvrow = [];
                        csvrow.push('title2');
                        csvrow.push('content2');
                        csv_data.push(csvrow.join(","));

                        var csvrow = [];
                        csvrow.push('title3');
                        csvrow.push('content3');
                        csv_data.push(csvrow.join(","));*/

                        //csv_data = csv_data.join('\n');
                        
                        jQuery.each(data, function (i, val) {
                            //console.log(val[0])

                           
        
                            //var rows = document.getElementsByTagName('tr');
                            //for (var i = 0; i < rows.length; i++) {
                
                                // Get each column data
                               // var cols = rows[i].querySelectorAll('td,th');
                
                                // Stores each csv row data
                                // var csvrow = [];
                                // for (var j = 0; j < val.length; j++) {
                                //     csvrow.push(val[j]);
                                // }
                                
                                var csvrow = [];
                                csvrow.push(`"${val[0]}"`);
                                csvrow.push(`"${val[1]}"`);
                                csvrow.push(`"${val[2]}"`)
                                csvrow.push(`"${val[3]}"`)
                                csvrow.push(`"${val[4]}"`)
                                csvrow.push(`"${val[5]}"`)
                                csvrow.push(`"${val[6]}"`)
                                csvrow.push(`"${val[7]}"`)
                                csvrow.push(`"${val[8]}"`)
                                csvrow.push(`"${val[9]}"`)
                                csvrow.push(`"${val[10]}"`)
                                csvrow.push(`"${val[12]}"`)
                                csv_data.push(csvrow.join(","));

                                // Combine each column value with comma
                                
                            //}
                
                            // Combine each row data with new line character
                            //csv_data = csv_data.join('\n');
                
                            // Call this function to download csv file 
                        
                        })
                       
                        csv_data = csv_data.join('\n');

                        downloadCSVFile(csv_data);
                    })
                }
            })
        }
 
        function downloadCSVFile(csv_data) {
 
            // Create CSV file object and feed
            // our csv_data into it
            CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });
 
            // Create to temporary link to initiate
            // download process
            var temp_link = document.createElement('a');
 
            // Download csv file
            temp_link.download = "MCC_All_POSTS.csv";
            var url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;
 
            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);
 
            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            $("#btn-export").attr("disabled", false)
            $(".loader-csv").addClass("hidden")
        }
        
        $("#btn-export").on("click", function() {
            $(this).attr("disabled", true)
            $(".loader-csv").removeClass("hidden")

            let post_ids = [];

            Object.entries(table.rows().data()).forEach(function(item) {
                if (item[1].id != undefined) {
                    post_ids.push(item[1].id)
                }
            })

            let category_id = $("#category_parent").val()
            let cities = $("#post-filter").val()
            let categories = $("#post-filter-categories").val()

            tableToCSV(category_id, cities, categories)
        })
    }
})
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "articles" && window.location.href.includes('/mccadmin')) {
        let article_id = $("#article_id").val()
        let categories_selected = [];

        document.addEventListener("turbolinks:before-cache", function() {
            if (table != null) {
                table.destroy();
            }
        })

        let table = $('#table-articles').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "pageLength": 50,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[5,'desc']]
        });
        $("#table-articles_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $('.custom-tag-input').tagsinput();

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

        const pond_image = FilePond.create(document.querySelector('#article-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'article[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 800000,
        });

        const pond_seo_image = FilePond.create(document.querySelector('#article-seo-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 400,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'article[seo_attributes][image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 800000,
        });

        $("#article-city").select2({
            placeholder: `Select ${$('#article-city').prev().text()}`,
            minimumResultsForSearch: -1
        });
        
        // ClassicEditor.create(document.querySelector('#article-content'), { 
        //     language: 'en',
        //     simpleUpload: { uploadUrl: '/uploads' },
        //   }).then( editor => {
        //     window.editor = editor;
        //   } )
        //   .catch( err => {
        //     console.error( err.stack );
        // } );
          

        $("#article_app").on("click", function(){
            if ($(this).prop("checked") == false) {
                $("#article_app_home_slider").prop("checked", false)
                $("#article_app_home_slider").attr("disabled", true)
            }
            else {
                $("#article_app_home_slider").attr("disabled", false)
            }
        })

        function getCategories() {
            let heading = $("input[name='article[heading]']").data("heading");
            let url = `/mccadmin/articles/categories/getAll`;

            if (article_id != undefined)
                url += `/${article_id}`

            fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) { 
                if (response.ok) {
                    let categories;
                    response.json().then(function(data) {
                        var treeData = []
                        $.each(data.categories1, function(index, rowId){
                            treeData.push(data.categories1[index][0])
                        });

                        $("#categories-tree").dynatree({
                            checkbox: true,
                            selectMode: 2,
                            children: treeData,
                            onSelect: function(select, node) {
                                categories_selected = []
                                var selNodes = node.tree.getSelectedNodes();

                                var selKeys = $.map(selNodes, function(node) {
                                    categories_selected.push(node.data.key)
                                    //return "[" + node.data.key + "]: '" + node.data.title + "'";
                                });
                                $("input[name='article[categories]']").val(categories_selected.join(","));
                         
                            },
                        });
                        
                        if ($("#article-selected_categories").val() != undefined) {
                            for(let category of $("#article-selected_categories").val().split(',')) {
                                $("#categories-tree").dynatree("getTree").selectKey(category)
                            }
                        } 
                    })
                }
            })
        }

        $("#table-articles tbody").on("click", ".delete-element", function() {
            id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/articles/articles/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table.row($(`tr[data-element_id='${id}']`)).remove().draw();
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

        $(".destroy-element").on("click", function() {
            id = $("#article_id").val()
         
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/mccadmin/articles/articles/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                           window.location = "/mccadmin/articles/articles"
                        }
                        else {
                            
                        }
                    })
                }
            }).then((result) => {})
        })

        getCategories()
    }
})